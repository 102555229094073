import React from "react"

import Observer from '@researchgate/react-intersection-observer'
import { Container, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, CustomInput, Spinner } from 'reactstrap';

import Layout from '../components/Layout'
import Metadata from '../components/Metadata'
import MovieCardLargeList from "../components/MovieCardLargeList"

import {GENRES_FR, ORDER} from '../const'

import { parseUrlParams } from '../utils'
import { logEvent } from "../api";
import { getI18nextLanguage } from "../utils/localStorage";
import { graphql } from "gatsby";
import { Trans, withTranslation } from "react-i18next";

class SearchPage extends React.Component {
  state = {
    movies: [],
    next: null,
    startFetching: false,
    isLoading: false,
    genreDropdownOpen: false,
    orderDropdownOpen: false,
    genre: null,
    order: 'release_date',
    query: '',
    prevLocationQuery: null,
    lastLoggedQuery: null,
    errorMessage: null,
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      require("intersection-observer")
    }
  }
  
  componentDidMount(){
    const genre = this.props.location.state && this.props.location.state.genre ? this.props.location.state.genre : this.state.genre
    const order = this.props.location.state && this.props.location.state.order ? this.props.location.state.order : this.state.order
    this.setState({genre, order, startFetching: true})
    this.parseUrlAndUpdateState()
    // this.fetchNextPage(null, genre, order)
  }

  async componentDidUpdate(){
    if(!(this.props.location.state && this.props.location.state.query)) {
      return
    }

    if (this.props.location.state && this.state.prevLocationQuery !== this.props.location.state.query && !this.state.startFetching) {
      await this.setState({ movies: [], next: null, startFetching: true })
      this.parseUrlAndUpdateState()
    }
  }

  handleNextPageLoad = async (event, isIntersecting) => {
    if (isIntersecting && this.state.next && !this.state.isLoading && event.intersectionRatio === 1) {
      await this.setState({isLoading: true})
      await this.fetchNextPage()
    }
  }

  parseUrlAndUpdateState = () => {
    const params = parseUrlParams(this.props.location.search);
    this.setState({ 
      query: params.query,
      prevLocationQuery: params.query,
      genre: params.genre,
      order: params.order,
    });
    this.fetchNextPage(params.query, params.genre, params.order);
  }

  handleGenreChange = async genre => {
    if (genre === this.state.genre) return
    await this.setState({ movies: [], next: null, genre: genre })
    this.fetchNextPage(this.state.query, genre, this.state.order)
  }

  handleOrderChange = async order => {
    if (order === this.state.order) return
    await this.setState({ movies: [], next: null, order: order })
    this.fetchNextPage(this.state.query, this.state.genre, order)
  }

  fetchNextPage = async (query = null, genre = null, order = null) => {
    // await this.setState({startFetching: true});
    const {movies, next} = this.state
    let params = '?'
    if (query) params += `&query=${query}`
    if (genre) params += `&genre=${genre}`
    if (order) params += `&order=${order}`
    params += "&streaming=true"

    let lang = await getI18nextLanguage()
    const langParam = !lang ? '' :  `&language=${lang}`

    let url = `${process.env.GATSBY_API_LIVE_URL}/fr/movie${params}${langParam}`
    if (next) {
      let newUrl = new URL(next)
      let hostname = process.env.GATSBY_API_LIVE_URL
      if (hostname.endsWith('/')) hostname = hostname.slice(0, -1)
      url = `${hostname}${newUrl.pathname}${newUrl.search}`
    } else {
      // // Log search event 
      // const slug = `${this.props.location.pathname}${this.props.location.search}`
      // logEvent("SearchQuery", slug, "Query", query, null)
    }

    const response = await fetch(url, {
      // headers: {'content-type': 'application/x-www-form-urlencoded'},
      method: 'GET',
    })
    this.setState({startFetching: false})
    if (response.ok) {
      const result = await response.json()
      // Log search event 
      const slug = `${this.props.location.pathname}${this.props.location.search}`
      
      
      if (query !== this.state.lastLoggedQuery && query !== null){
        logEvent("SearchQuery", slug, "Query", query.toLowerCase(), null)
        this.setState({ lastLoggedQuery: query })
      }

      if (!result.next && !result.previous && !result.count) {
        const {t} = this.props
        let message = t('Pas de résultat pour la recherche')
        await this.setState({errorMessage: `${message} "${query}"`,  startFetching: false,})
        return
      }
      await this.setState({
        movies: [...movies, ...result.results], 
        next: result.next,
        isLoading: false,
        startFetching: false,
        errorMessage: null,
      });
      return
    }
    const errMessage = await response.text()
    return errMessage
  }

  toggleGenreDropdown = () => {
    this.setState({
      genreDropdownOpen: !this.state.genreDropdownOpen,
    })
  }

  toggleOrderDropdown = () => {
    this.setState({
      orderDropdownOpen: !this.state.orderDropdownOpen,
    })
  }

  render() {
    const { movies, genre, order } = this.state
    const genres = [];
    for (let genre in GENRES_FR) {
      genres.push(
        <DropdownItem key={genre} active={this.state.genre === genre} onClick={() => this.handleGenreChange(genre)}> {GENRES_FR[genre]} </DropdownItem>
      )
    }

    // const genreTitle = genre && `(${GENRES_FR[genre].toLowerCase()})` || '(tout)'
    const genreTitle = genre && `${GENRES_FR[genre]}` || 'Tous les genres'

    // const ordreTitle = order && `(${ORDER[order].toLowerCase()})`
    const ordreTitle = order && `${ORDER[order]}`

    const {t} = this.props

    return (
      <Layout>
        <Metadata 
          title={this.state.query > 3 || t("Chercher des Films, Dessins Animés et Documentaires")}
          pathname={this.props.location.pathname}
          description={`${t("Page de résultats pour la recherche")}: ${this.state.query}`}
          />
        <Container>
          <Row className="mt-3">
            <Col lg={{size: 8, order: 1}} xs={{order: 2, size: 12}}>
            {this.state.errorMessage ? 
              <p className="text-danger text-center" style={{marginBottom: 500}}>{this.state.errorMessage}</p> : <MovieCardLargeList movies={movies}/>
            }
              
            </Col>
            <Col lg={{size: 4, order: 2}} xs={{order: 1, size: 12}}>
              <div className="bg-white px-4 py-3 mb-3">
              <h5 className="font-weight-bold text-dark"> <Trans>Filtres de recherche</Trans> </h5>
                        <div className="">
                          {/* <CustomInput type="checkbox" id="films" label="Films" />
                          <CustomInput type="checkbox" id="bas" label="Bandes annonces" /> */}
                          {/* <Dropdown isOpen={this.state.genreDropdownOpen} toggle={this.toggleGenreDropdown} className="mr-1">
                            <DropdownToggle caret color="light" className="btn-sm">
                              { genreTitle }
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => this.handleGenreChange(null)}>Tous les genres</DropdownItem>
                              <DropdownItem divider />
                              <div style={{maxHeight: 200, overflow: 'scroll'}}>
                                {genres}
                              </div>
                            </DropdownMenu>
                          </Dropdown>

                          <Dropdown isOpen={this.state.orderDropdownOpen} toggle={this.toggleOrderDropdown} className="mt-1">
                            <DropdownToggle caret color="light" className="btn-sm">
                              { ordreTitle }
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem active={this.state.order === 'a-z'} onClick={() => this.handleOrderChange('a-z')} >a-z</DropdownItem>
                              <DropdownItem active={this.state.order === 'popularity'} onClick={() => this.handleOrderChange('popularity')} >Popularité</DropdownItem>
                              <DropdownItem active={this.state.order === 'avg_rating'} onClick={() => this.handleOrderChange('avg_rating')} >Note moyenne</DropdownItem>
                              <DropdownItem active={this.state.order === 'rating_number'} onClick={() => this.handleOrderChange('rating_number')} >Nombre de votes</DropdownItem>
                              <DropdownItem active={this.state.order === 'release_date'} onClick={() => this.handleOrderChange('release_date')} >Dernières sorties</DropdownItem>
                              <DropdownItem active={this.state.order === 'coming_soon'} onClick={() => this.handleOrderChange('coming_soon')} >Prochaines sorties</DropdownItem>
                              <DropdownItem>Date d'ajout</DropdownItem>
                            </DropdownMenu>
                          </Dropdown> */}
                        </div>
              </div>

              {/* <div className="bg-white px-4 py-3">
                <h6 className="font-weight-bold text-dark">Autres listes</h6>
                <ul className="list-unstyled">
                  <li>Meilleurs films d'aventure de tous les temps</li>
                  <li>Meilleurs films Français de tous les temps</li>
                  <li>Meilleurs films de 2018</li>
                </ul>
              </div>  */}

            </Col>
          </Row>
        </Container>
          <Observer onChange={this.handleNextPageLoad} rootMargin="0% 0% 25%">
            <div className="text-center">{this.state.isLoading && <Spinner className="m-auto" size="sm" />}</div>
          </Observer>
      </Layout>
    )
  }
}

export default withTranslation()(SearchPage)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// const Container = styled.div`
//   width: 100%;
//   margin: 2em auto;
// `

// export const query = graphql`
// {
// 	allInternalMovies(
//     filter: {
//       movie:{genres: {regex: "//"}}
//     }
//   ){
//     edges {
//       node {
//         id
//         title
//         slug
//         language
//         synopsis
//         poster
//         poster_thumb {
//           x
//           y
//           url
//         }
//         avg_rating
//         videos {
//           type
//         }
//         analytics {
//           views_last_month
//           views_last_three_days
//           total_views
//           avg_time_on_page
//         }
//         movie {
//           alternative_id
//           original_title
//           original_language
//           original_country
//           budget
//           box_office
//           runtime
//           release
//           genres
//         }
//       }
//     }
//   }
// }
// `